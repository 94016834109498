import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a href="https://jess.abysmal.dev">
          jess.abysmal.dev
        </a>
      </header>
    </div>
  );
}

export default App;
